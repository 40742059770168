@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-image: url('../assets/lines.png'), url('../assets/bigbg.jpg');
  background-repeat: repeat, no-repeat;
  background-position:
    0 0,
    50% 50%;
  background-size: auto, cover;
  background-attachment: scroll, fixed;
}
